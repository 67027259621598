<template>
  <div>
    <div class="class--back" @click="iSThrowOutFun"></div>
    <div class="class--forward" @click="$emit('forward')"></div>
  </div>
</template>

<script>
  export default {
    props:{
      iSThrowOut:{
        type:Boolean,
        default:false
      },
    },
    name: "classBut",
    methods:{
      iSThrowOutFun(){
        if ( this.iSThrowOut ) {
          this.$emit('backOfff')
        }else {
          this.$router.back(-1)
        }
      },
    },
  }
</script>

<style scoped>

</style>
